<template>
  <div v-loading="loading" :element-loading-text="loadingText">
    <el-card class="main-chart-container">
      <div class="entity-navbar">
        <div class="left">
          <p class="entity-title">{{ getFieldLabel }}</p>
        </div>
        <div class="right d-flex">
          <el-select
            v-model="showFields.chart_field"
            placeholder="Select chart field"
            style="width: 100%"
            class="mr-1"
          >
            <el-option
              v-for="(field, i) in getEntityFieldsByType([
                'SELECT',
                'YES_OR_NO',
                'MULTI_SELECT',
                'ENTITY',
              ]).filter((e) => e.key != showFields.groupBy_chart_field)"
              :key="field.key + 'chart' + i"
              :label="field.label"
              :value="field.key"
              >{{ field.label }}</el-option
            >
          </el-select>
          <el-select
            v-model="showFields.groupBy_chart_field"
            placeholder="Select groupBy field"
            style="width: 100%"
            class="mr-1"
            :clearable="true"
          >
            <el-option
              v-for="(field, i) in getEntityFieldsByType([
                'SELECT',
                'YES_OR_NO',
                'MULTI_SELECT',
                'ENTITY',
              ]).filter((e) => e.key != showFields.chart_field)"
              :key="field.key + 'chart' + i"
              :label="field.label"
              :value="field.key"
              >{{ field.label }}</el-option
            >
          </el-select>
          <el-select
            v-model="showFields.chart_type"
            placeholder="Select chart field"
            style="width: 100%"
          >
            <el-option
              v-for="(field, index) of chartTypes"
              :value="field.value"
              :key="field.value + 'chart_type' + index"
              :label="field.label"
            >
              <span>{{ field.label }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="chart-main-container">
        <div
          class="pie-body"
          v-if="chartData && chartData.labels.length && !refresh"
        >
          <Bar
            v-if="getChartType == 'BAR' && !refresh"
            :chart-options="chartOptionsForPiechart"
            :chart-data="chartData"
          />
          <Doughnut
            v-else-if="getChartType == 'DOUGHNUT' && !refresh"
            :chart-options="chartOptions"
            :chart-data="chartData"
          />
          <LineChart
            v-else-if="getChartType == 'LINE' && !refresh"
            :chart-options="chartOptionsForPiechart"
            :chart-data="chartData"
          />
          <Pie
            v-else-if="!refresh"
            :chart-options="chartOptions"
            :chart-data="chartData"
          />
          <span
            v-if="currentEntity && currentEntity.name && getChartType != 'BAR'"
            class="mt-1 mb-1"
            style="font-size: 18px"
            >{{ currentEntity.name }}</span
          >
        </div>
        <div class="entity-empty" v-else>
          <el-empty :description="getDescription"></el-empty>
        </div>
      </div>
      <!-- <el-dialog
        :width="getIsMobile ? '100%' : '60%'"
        title="Entity Table"
        :visible.sync="tableVisible"
      >
        <div>
          <CustomDashboardTable
            v-if="tableVisible"
            :component="component"
            :entity_id="this.entity_id"
            :newFilters="filters"
            :customFilters="[]"
            :hide_options="true"
            :hide_expend="true"
          ></CustomDashboardTable>
        </div>
      </el-dialog>-->
    </el-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import axios from "@/config/axios";
export default {
  data() {
    return {
      selectedField: "",
      chartData: {
        labels: [],
        datasets: [
          // {
          //   backgroundColor: [],
          //   data: [],
          //   label: ""
          // }
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        //onClick: this.graphClicked,
      },
      chartOptionsForPiechart: {
        responsive: true,
        maintainAspectRatio: false,
      //  onClick: this.graphClicked,
        plugins: {
          tooltip: {
            position: "nearest",
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                if (
                  context.parsed.y !== null &&
                  context.dataset.currency_field?.inputType == "CURRENCY" &&
                  context?.dataset?.currency_field?.validations
                ) {
                  let type = context.dataset.currency_field.validations.currency
                    ? context.dataset.currency_field.validations.currency
                    : "USD";
                  let locale = context.dataset.currency_field.validations.locale
                    ? context.dataset.currency_field.validations.locale
                    : "en-US";
                  label += new Intl.NumberFormat(locale, {
                    style: "currency",
                    currency: type,
                  }).format(context.parsed.y);
                } else {
                  label += context.parsed.y;
                }
                return label;
              },
            },
          },
        },
      },
      componentData: {},
      colors: [
        "#57e2cf",
        "#57aee3",
        "#5669e2",
        "#8b57df",
        "#8b56e2",
        "#cf56e3",
        "#e256ad",
        "#e35667",
        "#e48851",
        "#e2cf56",
        "#ade256",
        "#66e259",
        "#56e289",
        "#69d4ce",
        "#cff57b",
        "#94dafb",
        "#fc8080",
        "#6d838e",
        "#279ffd",
        "#26e7a6",
        "#fdbc3a",
        "#f9b1b2",
        //"#8b75d7"--purple light,
        "#36bdf7",
      ],
      baseColor: "#65cff0",
      loading: false,
      entity_id: "",
      currentEntity: {},
      // showFields: {
      //   profile: "",
      //   title: "",
      //   description: "",
      //   fields: [],
      //   chart_field: "",
      //   chart_type: "PIE",
      //   board_field: "",
      // },
      loadingText: "Gathering data...",
      refresh: false,
      tableVisible: false,
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
        {
          label: "Line",
          value: "LINE",
        },
      ],
      customDashboardPieStats: null,
      savedColors: [],
      entityData: [],
    };
  },
  computed: {
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityRecordsForTable",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
    ]),
    getFieldLabel() {
      if (this.showFields?.chart_field) {
        if (
          this.showFields?.chart_field &&
          this.showFields.chart_field.includes("#")
        ) {
          let field = this.getEntityFieldsByType([
            "SELECT",
            "YES_OR_NO",
            "MULTI_SELECT",
            "ENTITY",
          ]).find((e) => e.key == this.showFields.chart_field);
          if (field?.label) {
            return field.label;
          }
          return this.currentEntity?.name;
        }
        return this.currentEntity?.name;
      }
      return this.currentEntity?.name;
    },
    getDescription() {
      if (!this.showFields.chart_field) {
        return "Field not selected...";
      }
      return "No data...";
    },
    getChartType() {
      return this.showFields.chart_type;
    },
  },
  mixins: [NavigationHelper],
  components: {
    Pie: () => import("@/components/customDashboard/charts/pie.vue"),
    Bar: () => import("@/components/customDashboard/charts/bar.vue"),
    Doughnut: () => import("@/components/customDashboard/charts/doughnut.vue"),
    LineChart: () =>
      import("@/components/customDashboard/charts/lineChart.vue"),
    // CustomDashboardTable: () =>
    //   import("@/components/customDashboard/customDashboardTable.vue"),
  },
  watch: {
    "showFields.chart_field": {
      handler: function (newValue, oldValue) {
        if (oldValue !== newValue) {
          this.getNecessaryInfo();
        }
      },
      deep: true,
    },
    "showFields.groupBy_chart_field": {
      handler: function (newValue, oldValue) {
        if (oldValue !== newValue) {
          this.getNecessaryInfo();
        }
      },
      deep: true,
    },
  },
  props: [
    "entityId",
    "search_string",
    "showFields",
    "isApplicationUserSide",
    "currentMenu",
    "getSelfEntityFilters",
  ],
  mounted() {
    this.entity_id = this.$route.params.entity_id || this.entityId;
    if (this.$route.query.chart_type) {
      this.showFields.chart_type = this.$route.query.chart_type;
    }
    if (this.$route.query.chart_field) {
      this.showFields.chart_field = this.$route.query.chart_field;
    }
    this.getNecessaryInfo();
  },
  methods: {
    resetChartData() {
      this.chartData.labels = [];
      this.chartData.datasets = [
        {
          backgroundColor: [],
          data: [],
          label: "",
        },
      ];
    },
    async updateRouterParams() {
      let query = this.getNavigationQuery(this.$route.query);
      query["key"] = this.$route.query.key;
      query["chart_field"] = this.showFields.chart_field;
      query["chart_type"] = this.showFields.chart_type;
      const currentQuery = this.$route.query;
      if (JSON.stringify(currentQuery) !== JSON.stringify(query)) {
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
    }
  },

    async loadChartData() {
      if (this.showFields.chart_field) {
        const availableFields = this.getEntityFieldsByType([
            'SELECT',
            'YES_OR_NO',
            'MULTI_SELECT',
            'ENTITY',
          ]);
          const selectedField = availableFields.find(
            (field) => field.key === this.showFields.chart_field
          );
          if (!selectedField) {
            this.showFields.chart_field = null;
            return;
          }
        this.loading = true;
        this.loadingText = "Gathering " + this.currentEntity?.name + " data";
        let params = {};
        (params.entity_id = this.entity_id),
          (params.group_by = this.showFields.chart_field);
        if (
          this.isApplicationUserSide &&
          this.currentMenu?.entity_type == "RELATIONAL_ENTITY"
        ) {
          params.customFilters = [...this.getSelfEntityFilters];
        } else {
          params.customFilters = [];
        }
        if (this.showFields?.groupBy_chart_field) {
          params.sub_group_by = this.showFields.groupBy_chart_field;
        }
        // await this.$store.dispatch(
        //   "entities/fetchCustomDashboardPieStats",
        //   params
        // );
        const response = await axios.post("/entities-data/charts", params);
        this.customDashboardPieStats = { ...response.data };
        if (this.customDashboardPieStats && this.customDashboardPieStats.data) {
          this.entityData = this.customDashboardPieStats.data;
          this.customDashboardPieStats.data =
            this.customDashboardPieStats.data.map((e) => {
              if (e?.properties) {
                let value = (e.properties || []).find((e) => e.entity_label);
                if (value) {
                  e.entity_label = value.entity_label;
                }
              }
              return e;
            });
        }
        this.resetChartData();
        this.updateRouterParams();
        // this.mapChartData();
        this.newMappedData();
        this.loading = false;
      }
    },
    async newMappedData() {
      this.loading = true;
      this.refresh = true;
      this.savedColors = [];
      this.chartData.labels = this.getGroupLables(
        this.customDashboardPieStats.data
      );
      this.chartData.datasets = this.getGroupDatasets(
        this.customDashboardPieStats.data,
        this.showFields.chart_field,
        this.chartData.labels
      );
      this.rawData = JSON.parse(JSON.stringify(this.chartData));
      console.log("this.chartData", this.chartData, this.rawData);
      this.chartData.labels = this.chartData.labels.map((e) => {
        if (e.includes("#")) {
          return e.split("#")[1];
        } else {
          return e;
        }
      });
      this.chartData.datasets = this.chartData.datasets.map((e) => {
        if (e.label && e.label.includes("#")) {
          e.label = e.label.split("#")[1];
        }
        return e;
      });
      setTimeout(() => {
        this.loading = false;
        this.refresh = false;
      }, 100);
    },
    getGroupDatasets(data, field, labels) {
      if (this.showFields?.groupBy_chart_field) {
        let result = [],
          sub_group_by = [];
        (data || []).forEach((el) => {
          if (el?.properties) {
            (el.properties || []).forEach((e) => {
              if (
                e?.label &&
                sub_group_by.indexOf(
                  e.label + (e.sub_entity_label ? "#" + e.sub_entity_label : "")
                ) == -1
              ) {
                if (
                  this.componentData
                    ?.pie_chart_field_group_by_selected_options &&
                  this.componentData.pie_chart_field_group_by_selected_options
                    .length
                ) {
                  if (
                    this.componentData.pie_chart_field_group_by_selected_options.indexOf(
                      e.label
                    ) != -1
                  ) {
                    sub_group_by.push(
                      e.label +
                        (e.sub_entity_label ? "#" + e.sub_entity_label : "")
                    );
                  }
                } else {
                  sub_group_by.push(
                    e.label +
                      (e.sub_entity_label ? "#" + e.sub_entity_label : "")
                  );
                }
              }
            });
          }
        });
        (sub_group_by || []).forEach((sub_label) => {
          let counts = [];
          (labels || []).forEach((label) => {
            let group = (data || []).find(
              (e) =>
                e._id + (e.entity_label ? "#" + e.entity_label : "") == label
            );
            if (
              group?.properties &&
              (group.properties || []).findIndex(
                (o) =>
                  o.label +
                    (o.sub_entity_label ? "#" + o.sub_entity_label : "") ==
                  sub_label
              ) != -1
            ) {
              let property = (group.properties || []).find(
                (o) =>
                  o.label +
                    (o.sub_entity_label ? "#" + o.sub_entity_label : "") ==
                  sub_label
              );
              if (
                this.componentData.sum_fields &&
                this.componentData.sum_fields.length
              ) {
                let sum_field =
                  this.componentData.sum_fields &&
                  this.componentData.sum_fields[0]
                    ? this.componentData.sum_fields[0]
                    : null;
                counts.push(property[sum_field] || 0);
              } else {
                counts.push(property.count);
              }
            } else {
              counts.push(0);
            }
          });
          let sum_field;
          if (
            this.componentData.sum_fields &&
            this.componentData.sum_fields.length
          ) {
            sum_field =
              this.componentData.sum_fields && this.componentData.sum_fields[0]
                ? this.componentData.sum_fields[0]
                : null;
          }
          result.push({
            label: sub_label,
            backgroundColor: this.generateRandomColors(1),
            data: counts,
            currency_field: sum_field ? this.getField(sum_field) : false,
          });
        });
        return result;
      } else if (this.checkIsDate) {
        if (
          this.componentData.sum_fields &&
          this.componentData.sum_fields.length
        ) {
          let result = [];
          (this.componentData.sum_fields || []).forEach((el) => {
            let mappedData = [];
            if (this.componentData?.selectedDateRange === "YEARLY") {
              labels.forEach((e) => {
                let yearData = data.find((dt) => dt?._id == e);
                mappedData.push(yearData?.[el] ? yearData[el] : 0);
              });
            } else {
              labels.forEach((e, i) => {
                let yearData = data.find((el) => el?._id == i + 1);
                mappedData.push(yearData?.[el] ? yearData[el] : 0);
              });
            }
            result.push({
              label: this.getLabel(el),
              backgroundColor: this.generateRandomColors(1),
              data: mappedData,
              currency_field: el ? this.getField(el) : false,
            });
          });
          return result;
        } else {
          let mappedData = [];
          if (this.componentData?.selectedDateRange === "YEARLY") {
            labels.forEach((e) => {
              let yearData = data.find((el) => el?._id == e);
              mappedData.push(yearData?.count ? yearData.count : 0);
            });
          } else {
            labels.forEach((e, i) => {
              let yearData = data.find((el) => el?._id == i + 1);
              mappedData.push(yearData?.count ? yearData.count : 0);
            });
          }
          return [
            {
              label: this.getLabel(field),
              backgroundColor: this.generateRandomColors(mappedData.length),
              data: mappedData,
            },
          ];
        }
      }
      let colors = [];
      //generating colors for PIE & DOUGHNUT without groupBy and sub-groupBy
      if (
        this.showFields?.chart_type == "DOUGHNUT" ||
        this.showFields?.chart_type == "PIE"
      ) {
        if (this.savedColors.length) {
          colors = this.savedColors;
        } else {
          colors = this.generateRandomColors(data.length);
          this.savedColors = colors;
        }
        if (
          this.componentData.sum_fields &&
          this.componentData.sum_fields.length
        ) {
          let result = [];
          (this.componentData.sum_fields || []).forEach((el) => {
            result.push({
              label: this.getLabel(el),
              backgroundColor: colors,
              data: (data || []).flatMap((e) => e[el]),
              currency_field: el ? this.getField(el) : false,
            });
          });
          return result;
        } else {
          return [
            {
              label: this.getLabel(field),
              backgroundColor: colors,
              data: (data || []).flatMap((e) => e.count),
            },
          ];
        }
      } else {
        if (
          this.componentData.sum_fields &&
          this.componentData.sum_fields.length
        ) {
          let result = [];
          (this.componentData.sum_fields || []).forEach((el) => {
            result.push({
              label: this.getLabel(el),
              backgroundColor: this.generateRandomColors(
                this.componentData.sum_fields.length
              ),
              data: (data || []).flatMap((e) => e[el]),
              currency_field: el ? this.getField(el) : false,
            });
          });
          return result;
        } else {
          //generating single color for Bar & Line graphs without groupBy and sub-groupBy
          return [
            {
              label: this.getLabel(field),
              backgroundColor: this.generateRandomColors(1),
              data: (data || []).flatMap((e) => e.count),
            },
          ];
        }
      }
    },
    getGroupLables(data) {
      let result = [];
      if (this.checkIsDate) {
        if (this.componentData?.selectedDateRange == "YEARLY") {
          if (this.currentYearSpan && this.currentYearSpan.length) {
            result = [...this.currentYearSpan];
          } else {
            let currentYear = new Date().getFullYear();
            for (let i = currentYear; i >= currentYear - 10; i--) {
              result.push(i.toString());
            }
            result.reverse();
            this.$set(this.componentData, "startYear", result[0]);
            this.$set(this.componentData, "endYear", result[result.length - 1]);
            this.currentYearSpan = [...result];
          }
        } else {
          result = this.months;
        }
        return result;
      }
      return (data || []).flatMap(
        (e) => e._id + (e.entity_label ? "#" + e.entity_label : "")
      );
    },
    mapChartData() {
      this.loading = true;
      if (this.customDashboardPieStats && this.customDashboardPieStats.data) {
        this.chartData.labels = this.getChartLabels(
          this.customDashboardPieStats.data,
          this.showFields.chart_field
        );
        this.chartData.datasets = this.getDataSets(
          this.chartData.labels,
          this.customDashboardPieStats.data
        );
        this.chartData.labels = this.chartData.labels.map((e) => {
          if (e.includes("#")) {
            return e.split("#")[0];
          } else {
            return e;
          }
        });
        this.chartData.datasets = this.chartData.datasets.map((e) => {
          if (e.label && e.label.includes("#")) {
            e.label = e.label.split("#")[0];
          }
          return e;
        });
      }
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityById", {
        entity_id: this.entity_id,
      });
      if (this.getEntityDataById) {
        this.currentEntity = this.getEntityDataById;
        this.loadChartData();
      }
      this.loading = false;
    },
    getEntityFieldsByType(types) {
      let results = [];
      (this.currentEntity && this.currentEntity.templates
        ? this.currentEntity.templates
        : []
      ).forEach((temp) => {
        if (temp?.templateInfo?.sections && !temp.is_repeatable) {
          if (types && types.length) {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields
                .filter((e) => types.indexOf(e.inputType) != -1)
                .map((el) => {
                  let key = el.key;
                  if (key && !key.includes("#")) {
                    key = temp.template_id + "#" + key;
                  }
                  el.key = key;
                  return el;
                }),
            ];
          } else {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields.map((el) => {
                let key = el.key;
                if (key && !key.includes("#")) {
                  key = temp.template_id + "#" + key;
                }
                el.key = key;
                return el;
              }),
            ];
          }
        }
      });
      if (
        types.includes("IMAGE") &&
        this.currentEntity?.entity_type == "INDIVIDUAL"
      ) {
        results.push({
          label: "Default picture",
          key: "defaultpic",
        });
      }
      return results;
    },
    getRandomColor(existColors) {
      let filterdColors = [];
      let indx = Math.floor(Math.random() * (23 - 0 + 1) + 0);
      if (existColors && existColors.length) {
        filterdColors = this.colors.filter(
          (color) => existColors.indexOf(color) == -1
        );
        if (filterdColors && filterdColors.length) {
          indx = Math.floor(Math.random() * filterdColors.length);
        } else {
          filterdColors = [];
          filterdColors = this.colors.filter(
            (color) => existColors.indexOf(color) == -1
          );
          indx = Math.floor(Math.random() * filterdColors.length);
        }
      } else {
        filterdColors = this.colors;
      }
      return filterdColors[indx];
    },
    generateRandomColors(max) {
      let result = [];
      if (max) {
        for (let i = 0; i < max; i++) {
          let userColor = this.getRandomColor(result);
          result.push(userColor);
        }
      }
      return result;
    },
    getChartLabels(data, field, filterLabels) {
      let result = [];
      let tid = field.split("#")[0];
      let fd = field.split("#")[1];
      let selectedField = (this.currentEntityFields || []).find(
        (e) => e.key == field
      );
      let isDate =
        selectedField && selectedField.inputType == "DATE" ? true : false;
      if (isDate) {
        if (this.componentData?.selectedDateRange == "YEARLY") {
          if (this.currentYearSpan && this.currentYearSpan.length) {
            result = [...this.currentYearSpan];
          } else {
            let currentYear = new Date().getFullYear();
            for (let i = currentYear; i >= currentYear - 10; i--) {
              result.push(i.toString());
            }
            result.reverse();
            this.$set(this.componentData, "startYear", result[0]);
            this.$set(this.componentData, "endYear", result[result.length - 1]);
            this.currentYearSpan = [...result];
          }
        } else {
          result = this.months;
        }
      } else {
        data.forEach((item) => {
          if (
            item.entityData &&
            item.entityData[tid] &&
            item.entityData[tid][fd]
          ) {
            if (
              typeof item.entityData[tid][fd] == "object" &&
              item.entityData[tid][fd].length
            ) {
              item.entityData[tid][fd].forEach((fl) => {
                let existedDataIndex = (result || []).findIndex((e) => e == fl);
                if (existedDataIndex == -1) {
                  result.push(fl);
                }
              });
            } else {
              let existedDataIndex = (result || []).findIndex((e) => {
                if (e.includes("#")) {
                  return e.split("#")[1] == item.entityData[tid][fd];
                } else {
                  return e == item.entityData[tid][fd];
                }
              });
              if (existedDataIndex == -1) {
                if (item.entityData[tid][fd + "/name"]) {
                  result.push(
                    item.entityData[tid][fd + "/name"] +
                      "#" +
                      item.entityData[tid][fd]
                  );
                } else {
                  result.push(item.entityData[tid][fd]);
                }
              }
            }
          }
        });
        if (filterLabels && filterLabels.length) {
          result = result.filter((e) => {
            if (e && e.includes("#")) {
              return filterLabels.indexOf(e.split("#")[1]) != -1;
            }
            return filterLabels.indexOf(e) != -1;
          });
        }
      }
      return result;
    },
    getDataSets(labels, data) {
      if (this.showFields?.groupBy_chart_field) {
        let tid = this.showFields.groupBy_chart_field.split("#")[0];
        let fd = this.showFields.groupBy_chart_field.split("#")[1];
        let result = [];
        let groupLabels = this.getChartLabels(
          data,
          this.showFields.groupBy_chart_field,
          []
        );
        (groupLabels || []).forEach((e) => {
          let value = e && e.includes("#") ? e.split("#")[1] : e;

          let counts = data.filter((item) => {
            if (
              item.entityData &&
              item.entityData[tid] &&
              item.entityData[tid][fd] == value
            ) {
              return true;
            }
          });
          let dataSet = this.getChartCount(
            labels,
            counts,
            this.showFields.chart_field,
            e
          );
          result = [...result, ...dataSet];
        });
        return result;
      } else {
        let result = this.getChartCount(
          labels,
          data,
          this.showFields.chart_field
        );
        return result;
      }
    },
    getChartCount(labels, data, field, label = "") {
      let tid = field.split("#")[0];
      let fd = field.split("#")[1];
      let counts = [];
      labels.forEach((e) => {
        let value = e && e.includes("#") ? e.split("#")[1] : e;
        let count = data.filter((item) => {
          if (
            item.entityData &&
            item.entityData[tid] &&
            item.entityData[tid][fd]
          ) {
            if (
              typeof item.entityData[tid][fd] == "object" &&
              item.entityData[tid][fd].length
            ) {
              let found = item.entityData[tid][fd].find((fl) => value == fl);
              return found ? true : false;
            } else if (item.entityData[tid][fd] == value) {
              return true;
            }
          }
        });
        counts.push(count && count.length ? count.length : 0);
      });
      return [
        {
          label: label ? label : this.getLabel(field),
          backgroundColor: this.generateRandomColors(
            this.chartData.labels.length
          ),
          data: counts,
        },
      ];
    },
    getLabel(field) {
      if (
        this.currentEntityFields &&
        this.currentEntityFields.length &&
        field
      ) {
        let foundField = this.currentEntityFields.find((e) => e.key == field);
        return foundField && foundField.label ? foundField.label : "";
      }
      return "";
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.key = data._id + "#" + e.key;
        return e;
      });
      return fields;
    },
  },
};
</script>
<style scoped lang="scss">
.main-chart-container {
  width: 100% !important;
  .entity-navbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 5px;
  }
  .left {
    .entity-title {
      color: #0e1216;
      font-weight: 500;
      font-size: 18px;
      margin-top: 5px;
    }
    order: 1;
  }

  .right {
    order: 2;
  }
  .chart-main-container {
    .entity-empty {
      text-align: center !important;
    }
    .pie-body {
      text-align: center !important;
    }
  }
}
</style>
